// this file should be kept very lightwieght and include styles for above the fold content.

form.main {
  margin: 4em 0;
  label {
    font-family: 'Lato';
    font-size: 18px;
    color: #212b36;
    font-weight: 400;
  }
  input {
    min-height: 50px;
  }
}
.form-subtext {
  margin: 4em 0 0;
}

@media(max-width:768px){.clearfix-xs:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
    }
}
.full-width{
  padding: 0 !important;
  .container{
    padding:0 !important;
    margin: 0 !important;
    max-width: 100vw !important;
    // max-height: 800px;
  }
}
